import React, { useEffect, useState } from 'react';
import { CommonBackContent } from '../Layouts/CommonBackContent';
import { useConfigProvider } from '@/context/ConfigProvider';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { HomeUpdateEmailKey, pageMetadataMap, PageType } from '@/shared/app-common';
import { useUserProvider } from '@/context/UserProvider';
import { getUserInfo, updateNotifationEmail } from '@/lib/service';
import { isApplePrivateEmail, isEmailValid } from '@/shared/utils';
import CommonSimpleModal from '../Common/CommonSimpleModal';
import { Input, message } from 'antd';
import { useTranslation } from 'next-i18next';
import { HomePreviewDataModel, UserInfo } from '@/types/home';
import { useWindowSize } from 'react-use';
import InstagramTrackingToolContent from './InstagramTrackingToolContent';
import Image from 'next/image';
import useSearchAccountGuide from '../Dashboard/useSearchAccountGuide';

const TopSearchContent = dynamic(() => import('../LandingPage/TopSearch'), { ssr: true });
const DetailShowContent = dynamic(() => import('./DetailShowContent'), { ssr: true });
const FeaturesContent = dynamic(() => import('./FeaturesContent'), { ssr: true });
const UseShowContent = dynamic(() => import('./UseShowContent'), { ssr: false });
const CustomersTalkUsContent = dynamic(() => import('./CustomersTalkUsContent'), { ssr: true });
const RecommendedArticleContent = dynamic(() => import('./RecommendedArticleContent'), {
  ssr: true,
});
const RegisterContent = dynamic(() => import('./RegisterContent'), { ssr: true });
const LandingPageIntroduce = dynamic(() => import('./LandingPageIntroduce'), { ssr: true });
const AddAccountContent = dynamic(() => import('../LandingPage/AddAccountContent'), { ssr: false });

const MemoCommonBackContent = React.memo(CommonBackContent);

const HomeContent = ({ data }: { data: any }) => {
  const { isBlogWeb } = useConfigProvider();
  const { isLogin } = useUserProvider();
  const { onShowArticleBottomRecentAlert } = useConfigProvider();
  const {
    onSearchAccount,
    searchAccountLoading,
    renderPrivateAccountModal,
    renderAddAccountModal,
  } = useSearchAccountGuide();
  const [preViewData, setPreViewData] = useState<HomePreviewDataModel>(data?.preViewData);

  const router = useRouter();
  const topInfo = React.useRef<HTMLDivElement>(null);
  const [isIntersecting, setIsIntersecting] = useState(false);
  const [showEditNotiEmail, setShowEditNotiEmail] = useState<boolean>(false);
  const [receiveEmail, setReceiveEmail] = useState<string>(null);
  const [uerInfo, setUserInfo] = useState<UserInfo>(null);
  const { t } = useTranslation();
  const { width } = useWindowSize();

  useEffect(() => {
    setPreViewData(data?.preViewData);
  }, [data]);

  useEffect(() => {
    onShowArticleBottomRecentAlert(width < 768);
    return onShowArticleBottomRecentAlert(false);
  }, [width]);

  const pageType = data.pageType as PageType;
  useEffect(() => {
    if (isBlogWeb) {
      router.push('/instagram-tracking-blog');
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // 确保在浏览器环境中
      if (isLogin && localStorage.getItem(HomeUpdateEmailKey) !== 'showed') {
        getUserInfo()
          .then((res) => {
            if (res?.code === 0) {
              if (
                (res?.data?.receive_email?.length > 0 &&
                  isApplePrivateEmail(res?.data?.receive_email)) ||
                isApplePrivateEmail(res?.data?.email)
              ) {
                setShowEditNotiEmail(true);
                setUserInfo(res?.data);
              }
              localStorage.setItem(HomeUpdateEmailKey, 'showed');
            }
          })
          .catch((e) => {});
      }
    }
  }, [isLogin]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(
        (entry) => {
          setIsIntersecting(entry.isIntersecting);
        },
        {
          threshold: [0.9],
        },
      );
    });

    if (topInfo.current) {
      observer.observe(topInfo.current);
    }

    return () => {
      if (topInfo.current) {
        observer.unobserve(topInfo.current);
      }
    };
  }, []);

  const onConfirmReceiveEmail = () => {
    updateNotifationEmail(receiveEmail)
      .then((res) => {
        if (res.code === 0) {
          message.success(t('Email for notifications updated successfully!'));
          setReceiveEmail(null);
        } else {
          message.error(res?.message);
        }
      })
      .catch((error) => {
        message.error(error?.message);
      });
  };

  const pageMetaData = pageMetadataMap[pageType ?? PageType.Normal];
  const [loading, setLoading] = useState(false);
  const [searchStr, setSearchStr] = useState('');
  const [inputVisible, setInputVisible] = useState(true);
  const [openModal, setOpenModal] = useState(false);

  const onValueChanged = (e?: string) => {
    setSearchStr(e ?? '');
  };

  const onSearch = (e?: string) => {
    if (!e) {
      message.error(t('Please enter the insert link or username'));
      return;
    }

    setSearchStr(e ?? '');
    setOpenModal(true);
  };

  return (
    <>
      {isBlogWeb ? (
        <></>
      ) : (
        <div style={{ minHeight: '100vh' }}>
          <MemoCommonBackContent>
            <div ref={topInfo}>
              <TopSearchContent
                topInfo={{
                  title: ` ${t(pageMetaData.title1Key)} `,
                  titleStyle: { color: '#0c0c0c' },
                  subTitle: ` ${t(
                    'Perfect for Marketers & Individuals—Track, Analyze, and Grow with Ease!',
                  )} `,
                  subTitleStyle: { color: '#725cff' },
                  desc: (
                    <>
                      <span style={{ color: 'var(--app-text-color)', fontWeight: '600' }}>
                        {t(pageMetaData.title2Key)}{' '}
                      </span>{' '}
                      {t(pageMetaData.titleDescKey)}
                    </>
                  ),
                  tags: [
                    t(`<p>Find the Truth</p>`),
                    t(`<p>Rich <span style='color:#725CFF'>Data</span></p>`),
                    t(`<p><span style='color:#725CFF'>AI</span>-Powered</p>`),
                    t(`<p><span style='color:#725CFF'>Privacy-Focused</span></p>`),
                  ],
                }}
                middleContent={
                  <div className="mt-10 flex justify-center items-center gap-1">
                    <Image
                      src={'/images/home/home_users_icon.webp'}
                      alt="home users icon"
                      width={60}
                      height={24}
                    />

                    <span style={{ fontWeight: 'bold' }}>{`50,000+`}</span>
                    <span
                      className="max-md:text-[0.875rem]"
                      style={{ color: 'rgba(12, 12, 12, 0.60)' }}
                    >
                      {t('users repeatedly use our service')}
                    </span>
                  </div>
                }
                loading={searchAccountLoading}
                searchVal={searchStr}
                onSearchClick={onSearchAccount}
                onValueChanged={onValueChanged}
                onInputVisibleChanged={(visible) => setInputVisible(visible)}
              />
            </div>
            {pageType === PageType.Normal && <DetailShowContent preViewData={preViewData} />}
            {pageType !== PageType.Normal && <LandingPageIntroduce pageType={pageType} />}
            <FeaturesContent preViewData={preViewData} />
            <UseShowContent />
            <InstagramTrackingToolContent />
          </MemoCommonBackContent>

          {data?.articleList?.length > 0 && (
            <RecommendedArticleContent dataList={data?.articleList} />
          )}
          <CustomersTalkUsContent
            contentInfo={data?.contentData?.contentInfo?.['section-user-reviews']}
          />

          <MemoCommonBackContent>
            <RegisterContent isIntersecting={isIntersecting} />
          </MemoCommonBackContent>

          {showEditNotiEmail && (
            <CommonSimpleModal
              open={showEditNotiEmail}
              title={t('Notice')}
              desc={
                <div>
                  <p>
                    {t(
                      `No email linked. Bind an email to get updates. You can skip now and add it later in`,
                    )}
                    {` `}
                    <a style={{ color: `#725CFF` }} href={'/settings'}>
                      {t('settings')}
                    </a>
                    .
                  </p>
                  <Input
                    placeholder={t('Email')}
                    style={{ marginTop: '20px', marginBottom: '30px' }}
                    onChange={(e) => setReceiveEmail(e?.target?.value)}
                  />
                </div>
              }
              closeBtnTitle={t('Skip for now')}
              btnTitle={t('Confirm')}
              closeAction={(isOk: boolean) => {
                if (isOk) {
                  if (!isEmailValid(receiveEmail)) {
                    return message.error(t('Please enter a valid email address'));
                  }
                  onConfirmReceiveEmail();
                }
                setShowEditNotiEmail(false);
              }}
            />
          )}

          {renderAddAccountModal()}
          {renderPrivateAccountModal()}
        </div>
      )}
    </>
  );
};

export default HomeContent;
